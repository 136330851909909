import { css, styled } from "styled-components";
import CarProperty from "./carProperty";
import React from "react";
import { Car } from "../../data/bruktbilsalgBackend/types/car.types";
import { translateFuel } from "../../utils/translateFuel";
import { numberWithSpaces } from "../../utils/numberWithSpacing";
import { tokens } from "@gnist/themes/tokens.css.js";

const PropertiesWrapper = styled.div`
  display: flex;
  margin-top: ${tokens.spacing.xs};
  margin-bottom: ${tokens.spacing.xs};
  gap: 16px;
`;

type props = {
  car: Car;
};

const CarProperties = ({ car }: props) => {
  return (
    <PropertiesWrapper>
      <CarProperty propertyDesc={"Årsmodell"} propertyVal={car.modelYear} />
      <CarProperty
        propertyDesc={"Kilometerstand"}
        propertyVal={`${numberWithSpaces(car.mileage)} km`}
      />
      <CarProperty
        propertyDesc={"Drivstoff"}
        propertyVal={`${translateFuel(car.fuel)}`}
      />
    </PropertiesWrapper>
  );
};

export default CarProperties;
