"use client";

import {
  Modal,
  PrimaryButton,
  TextField,
  AlertBanner,
  Icon,
  breakpointTablet,
  Heading2Text,
  DisplayText,
} from "@gnist/design-system";
import { useTradeInContext } from "@/components/tradeIn/tradeInProvider";
import { numberWithSpaces } from "@/utils/numberWithSpacing";
import { isMobile } from "@/utils/isMobile";
import { styled } from "styled-components";
import { WishesValuationCard } from "@/components/tradeIn/wishesValuationCard";
import { useEffect, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { tokens } from "@gnist/themes/tokens.css.js";

const MarketSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${tokens.spacing["3xl"]} ${tokens.spacing.l};
  position: relative;
`;

const Title = styled.div`
  ${Heading2Text.selector} {
    font-size: ${tokens.typeface.size.l};
    margin: 0 0 ${tokens.spacing.xxs};

    @media screen and (max-width: ${breakpointTablet}) {
      font-size: ${tokens.typeface.size.m};
    }
  }
`;

const ErrorBanner = styled(AlertBanner)`
  margin-bottom: ${tokens.spacing.l};
`;

const TradeInSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${tokens.color["surface-variant"]};
  color: ${tokens.color["on-surface-variant"]};
  border-top: 1px solid ${tokens.color.outline};
  padding: ${tokens.spacing.xxl} ${tokens.spacing.xl} ${tokens.spacing.xl};
`;

const TradeInLabel = styled.p`
  font-weight: 600;
`;

const TradeInPrice = styled.div`
  ${DisplayText.selector} {
    font-size: ${tokens.typeface.size["4xl"]};
    font-weight: 600;
    border-bottom: 4px solid ${tokens.color.outline};
    margin: ${tokens.spacing.base} 0 ${tokens.spacing.l};
  }
`;

const ChevronWrapper = styled.span`
  border: 1px solid ${tokens.color.outline};
  border-radius: 100%;
  height: ${tokens.size.xl};
  width: ${tokens.size.xl};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: calc((${tokens.size.xl} / 2) * -1);
  background-color: ${tokens.color.surface};
`;

const Disclaimer = styled.p`
  font-size: 10px;
  a {
    color: ${tokens.color["on-surface-variant"]};
  }
`;

export const TradeInModal = ({
  context,
  isDealerMoller = true,
}: {
  context: "car" | "overview";
  isDealerMoller?: boolean;
}) => {
  const {
    marketPrice,
    setMarketPrice,
    tradeInPrice,
    setTradeInPricingOn,
    isShowingTradeInModal,
    setIsShowingTradeInModal,
    isTradeInPricingOn,
  } = useTradeInContext();
  const [hasError, setHasError] = useState(false);

  const handleOnClick = () => {
    if (!marketPrice) {
      setHasError(true);
    } else {
      datadogRum.addAction(
        `Clicks button to see trade-in prices in ${context} page`,
      );
      setTradeInPricingOn(true);
      setIsShowingTradeInModal(false);
    }
  };

  useEffect(() => {
    if (isTradeInPricingOn && tradeInPrice === 0) setTradeInPricingOn(false);
  }, [isTradeInPricingOn, setTradeInPricingOn, tradeInPrice]);

  return (
    <Modal
      isOpen={isShowingTradeInModal}
      handleClose={() => setIsShowingTradeInModal(false)}
      size={isMobile() ? "fullscreen" : "medium"}
    >
      <MarketSection>
        <Title>
          <Heading2Text $as="h1">Hva tror du bilen din er verdt?</Heading2Text>
        </Title>
        <TextField
          label="Skriv inn pris"
          value={marketPrice ? numberWithSpaces(marketPrice) : ""}
          onChange={(e) => {
            hasError && setHasError(false);
            setMarketPrice(e.target.value);
          }}
          suffix="kr"
          reserveSpaceForMessage={false}
          validity={{
            type: hasError ? "error" : "none",
            message: hasError ? "Vennligst fyll inn dette feltet" : "",
          }}
        />
        <ChevronWrapper>
          <Icon icon="expand_more" />
        </ChevronWrapper>
      </MarketSection>
      <TradeInSection>
        <TradeInLabel>Innbyttepris*</TradeInLabel>
        <TradeInPrice>
          <DisplayText $as={"h2"}>
            {`${tradeInPrice && numberWithSpaces(tradeInPrice)} kr`}
          </DisplayText>
        </TradeInPrice>
        {hasError && (
          <ErrorBanner
            type="error"
            density="compact"
            message="For å oppdatere prisene må du først skrive inn det du tror bilen din er verdt."
          />
        )}
        <PrimaryButton onClick={() => handleOnClick()}>
          Se priser med innbytte
        </PrimaryButton>
        <WishesValuationCard
          context={context}
          isDealerMoller={isDealerMoller}
        />
        <Disclaimer>
          *Innbytteprisen er basert på det du selv tror bilen er verdt, minus
          det vi vanligvis må trekke for å reparere, vaske og selge bilen
          videre. Prisen er derfor ikke endelig. Du kan lese mer om{" "}
          <a
            href="https://mollerbil.no/aktuelt/kjop-og-salg/dette-gjor-vi-nar-vi-verdivurderer-bilen-din"
            target="_blank"
          >
            hva som gjøres når Møller Bil utfører en verdivurdering
          </a>{" "}
          på deres nettsider.
        </Disclaimer>
      </TradeInSection>
    </Modal>
  );
};
